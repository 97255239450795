import {
	CustomizationGroupDto,
	CustomizationItemDto,
	CustomizationType,
	getCustomizationClassByType
} from '../models/customization/customization.dto';
import { CreativeUnitWithCustomizationsDto } from '../models/creative-unit/creative-unit.dto';
import { MergeTags } from '../../_core/utils/utils.merge-tags';
import { Evaluate } from '../../_core/utils/utils.evaluate';
import { MergePropertiesUtils } from './merge-properties.utils';

export class CustomizationUtils {
	/**
	 * Recursively flatten all customizations into a single array.
	 */
	public static flattenCustomizations(customizations: CustomizationItemDto[]): CustomizationItemDto[] {
		return customizations.reduce<CustomizationItemDto[]>((acc, customization) => {
			acc.push(customization);

			if (customization.type === CustomizationType.GROUP) {
				acc = acc.concat(this.flattenCustomizations(customization.children));
			}

			return acc;
		}, []);
	}

	public static flattenCustomizationsExcludeGroups(customizations: CustomizationItemDto[], parentGroup: string = ""): CustomizationItemDto[] {
		return customizations.reduce<CustomizationItemDto[]>((acc, customization) => {

			if (customization.type === CustomizationType.GROUP) {
				parentGroup += (parentGroup ? " - " : "") + customization.label;
				acc = acc.concat(this.flattenCustomizationsExcludeGroups(customization.children, parentGroup));
			} else {
				customization["parentGroup"] = parentGroup || "No Group";
				acc.push(customization);
			}

			return acc;
		}, []);
	}

	/**
	 * Recursively find all customizations and return them in a flat array.
	 * NOTE: This does not return a boolean like a typical find method.
	 */
	public static findAllCustomizations(customizations: CustomizationItemDto[]): CustomizationItemDto[] {
		let foundCustomizations: CustomizationItemDto[] = [];

		customizations?.forEach(customization => {
			foundCustomizations.push(customization);

			if ((customization as CustomizationGroupDto).children) {
				foundCustomizations = foundCustomizations.concat(
					this.findAllCustomizations((customization as CustomizationGroupDto).children)
				);
			}
		});

		return foundCustomizations;
	}

	/**
	 * Iterate recursively through our customizations and apply a filter condition to them.
	 * Customizations that pass are retained.  You can also flatten the result into one array.
	 */
	public static filterAllCustomizations(
		customizations: CustomizationItemDto[],
		func: Function,
		flatten?: boolean
	): CustomizationItemDto[] {
		let filteredCustomizations: CustomizationItemDto[] = [];

		// console.log('Starting Filter check', customizations, flatten);

		customizations.forEach(customization => {
			// console.log('Filter Checking', customization.label, func(customization));

			if (func(customization)) {
				if ((customization as CustomizationGroupDto).children) {
					let children = this.filterAllCustomizations((customization as CustomizationGroupDto).children, func, flatten);
					if (flatten) {
						// Flatten the children into the main array
						// console.log('Flattening children', children, filteredCustomizations, filteredCustomizations.concat(children));
						filteredCustomizations = filteredCustomizations.concat(children);
					} else {
						// Add the parent with filtered children
						let newCustomization = {
							...customization,
							children: children
						};
						filteredCustomizations.push(newCustomization);
					}
				} else {
					// No children, just add the customization
					filteredCustomizations.push(customization);
				}
			} else {
				// Well we don't like this customization, but it may have children we like.
				// Only add them if we're flattening though.
				if (flatten && (customization as CustomizationGroupDto).children) {
					filteredCustomizations = filteredCustomizations.concat(
						this.filterAllCustomizations((customization as CustomizationGroupDto).children, func, flatten)
					);
				}
			}
		});

		return filteredCustomizations;
	}

	/**
	 * Iterate recursively through our customizations and apply a map function to them.
	 */
	public static mapAllCustomizations(customizations: CustomizationItemDto[], func: Function) {
		return customizations?.map(customization => {
			const newCustomization = { ...func(customization) };

			if ((newCustomization as CustomizationGroupDto).children) {
				newCustomization.children = this.mapAllCustomizations((newCustomization as CustomizationGroupDto).children, func);
			}

			return newCustomization;
		});
	}

	/**
	 * Iterate recursively through our package customizations and override them with the unit customizations.
	 */
	public static overrideCustomizations(
		packageCustomizations: CustomizationItemDto[],
		unitCustomizations: CustomizationItemDto[]
	): CustomizationItemDto[] {
		return this.mapAllCustomizations(packageCustomizations, (customization: CustomizationItemDto) => {
			const creativeUnitCustomization = unitCustomizations?.find(item => item.id === customization.id);

			return creativeUnitCustomization || customization;
		});
	}

	/**
	 * Move a customization up or down in the list of customizations.
	 * Customizations are moved within their parent group, or if they are not found, they are moved within the root.
	 */
	public static moveCustomization(
		customizations: CustomizationItemDto[],
		movedCustomization: CustomizationItemDto,
		direction: -1 | 1
	): CustomizationItemDto[] {
		let newCustomization: CustomizationItemDto[] = [...customizations];
		const isMovedCustomizationInCurrentLayer = newCustomization.includes(movedCustomization);

		if (isMovedCustomizationInCurrentLayer) {
			const customizationIndex = newCustomization.findIndex(c => c.id === movedCustomization.id);
			const newIndex = customizationIndex + direction;

			if (newIndex >= 0 && newIndex < newCustomization.length) {
				[newCustomization[customizationIndex], newCustomization[newIndex]] = [
					newCustomization[newIndex],
					newCustomization[customizationIndex]
				];
			}
		} else {
			// if not, then recurse through the children until we find the customization
			newCustomization.forEach(c => {
				if (c.type === CustomizationType.GROUP && c.children) {
					c.children = this.moveCustomization(c.children, movedCustomization, direction);
				}
			});
		}

		return newCustomization;
	}

	/**
	 * Looks through the visibility conditions of a customization and determines
	 * whether this customization should be visible to the end user based on the current conditions.
	 */
	public static isCustomizationVisible(customization: CustomizationItemDto, unit: CreativeUnitWithCustomizationsDto, additionalMergeState?: { [key: string]: any }): boolean {
		let state = {};

		if (unit) {
			state = {
				...state,
				...MergePropertiesUtils.getMergeableProperties(undefined, undefined, unit)
			};
		}

		if (additionalMergeState) {
			state = {
				...state,
				...(additionalMergeState || {})
			};
		};

		// console.log('Checking visibility conditions', customization, state, unit, additionalMergeState);

		if (customization.type === CustomizationType.DIMENSIONS && !additionalMergeState?.activeCreativeUnit) {
			return false;
		}

		if (customization.visibilityConditions) {
			let visible = true;

			// If visibilityConditions are not an array, wrap into an array.
			let conditions = customization.visibilityConditions;
			if (!Array.isArray(conditions)) {
				conditions = [conditions];
			}

			for (let condition of conditions) {
				// console.log('Checking condition', condition, Evaluate.evaluate(condition, state), state);

				// Apply merge tags to the condition.
				try {
					condition = JSON.parse(MergeTags.applyMergeTagsToString(JSON.stringify(condition), state));
				} catch (e) {
					console.warn('Unable to parse condition merge tags');
				}

				if (!Evaluate.evaluate(condition, state)) {
					visible = false;
					break;
				}
			}
			return visible;
		} else {
			// console.log('No visibility conditions, defaulting to true');
			return true;
		}
	}

	/**
	 * Recursively find all of the customizations who should be visible based on the current conditions
	 * of the Creative Unit.
	 */
	public static getAllVisibleCustomizations(
		customizations: CustomizationItemDto[],
		unit?: CreativeUnitWithCustomizationsDto,
		additionalMergeState?: { [key: string]: any }
	): CustomizationItemDto[] {
		return CustomizationUtils.filterAllCustomizations(customizations, customization => {
			return CustomizationUtils.isCustomizationVisible(customization, unit, additionalMergeState);
		});
	}

	/**
	 * Remove a customization from a creative unit.
	 */
	public static removeCustomization(
		creativeUnit: CreativeUnitWithCustomizationsDto,
		customizationId: string
	): CreativeUnitWithCustomizationsDto {
		// Remove the customization from the creative unit or from its parent customization.
		let newUnit = {
			...creativeUnit,
			customizations: creativeUnit.customizations
				.filter(c => c.id !== customizationId)
				.map(c => {
					if ((c as CustomizationGroupDto).children) {
						return {
							...c,
							children: (c as CustomizationGroupDto).children.filter(item => item.id !== customizationId)
						};
					}

					return c;
				})
		};

		return newUnit;
	}

	public static applyCustomizationConfig(customizations: CustomizationItemDto[], config: { [key: string]: string }) {
		return customizations.map(customization => {
			if (customization.type === CustomizationType.GROUP) {
				customization.children = this.applyCustomizationConfig(customization.children, config);
				return customization;
			}

			const value = config[customization.id];
			if (value === undefined) {
				return customization;
			}
			const customizationClass = getCustomizationClassByType(customization.type);

			return customizationClass?.applyStringValue(customization as any, value);
		});
	}
}
