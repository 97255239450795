import { Entity, Unique, PrimaryGeneratedColumn, Column, PrimaryColumn, Generated, Index, OneToMany, JoinColumn, ManyToOne } from 'typeorm';
import { Asset, PublicAsset } from '../asset/asset.entity';
import { AuthenticationStrategy, PublicAuthenticationStrategy } from '../authentication-strategy/authentication-strategy.entity';
import { Product } from '../product/product.entity';

export enum SignupMethod {
	Allow = 'allow',
	InviteOnly = 'inviteOnly'
}

export class Settings {
	assetProvider: string;
	defaultClipPath: string;
	defaultMusicPath: string;
	defaultVoiceoverPath: string;
	defaultLocale: string;
	siteTitle: string;
	siteFaviconUrl: string;
	customStyles?: string;
	aiVoiceEnabled: boolean;
	projectApprovalRequired?: boolean;
	defaultSubscriptionArray?: any[];
	defaultProductArray?: any[];
	emailReviewersOnFinalRender?: boolean;
	organizationLogo?: {
		url: string;
	};
	loginPageImage?: {
		url: string;
	};
	signupPageImage?: {
		url: string;
	};
	disclaimer?: string;
	terms?: string;
	freeTrialEnabled?: boolean;
	freeTrialDuration?: string;
	transcriptDownloadEnabled?: boolean;
	reviewProcessEnabled?: boolean;
	previewShareLinksEnabled?: boolean;
	projectStateExportEnabled?: boolean;
	disableCheckout?: boolean;
	downloadReceiptEnabled: boolean;
	paymentMethods?: any;
	emailName: string;
	emailAddress: string;
	emailService: string;
	emailRequirement?: string;
	singlePassExpire: string;
	singlePassLength: number;
	paymentMethodsRequired: boolean;
	bodyScripts: string;
	signupExtraPrivacyConditions: boolean;
	metadata?: any;
	legacyHeroBannerEnabled: boolean;
	primaryColor: string;
	migrateToForeverSubscriptions: boolean;
	legacyOrderByProvinceEnabled: boolean;
	exportsRequireApproval: boolean;
	disableReceiptButton: boolean;
	disableTermsPopup: boolean;
	enableNewCreativePackageRequests: boolean;
	creativePackageRequestFormId: string;
}

export type PublicSite = Pick<
	Site,
	| 'id'
	| 'remoteId'
	| 'organizationName'
	| 'slug'
	| 'enabled'
	| 'defaultAuthenticationStrategyId'
	| 'signupMethod'
	| 'settings'
	| 'defaultItemProduct'
	| 'defaultSubscriptionProduct'
	| 'created'
> & {
	authenticationStrategies?: PublicAuthenticationStrategy[];
	defaultAuthenticationStrategy?: PublicAuthenticationStrategy;
	assets?: PublicAsset[];
};

@Entity('sites')
@Index(['remoteId'], { unique: true })
export class Site {
	constructor(value?: Partial<Site>) {
		for (const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	public id: string;

	@Column('text', { nullable: false })
	public remoteId: string;

	@Column('text')
	public organizationName: string;

	@Column('text')
	public slug: string;

	@Column('boolean', { nullable: false })
	public enabled: boolean;

	@Column({
		type: 'enum',
		enum: SignupMethod,
		default: SignupMethod.InviteOnly
	})
	public signupMethod: SignupMethod;

	@OneToMany(
		() => AuthenticationStrategy,
		authenticationStrategy => authenticationStrategy.site,
		{
			eager: false
		}
	)
	authenticationStrategies?: AuthenticationStrategy[];

	@Column('text', { nullable: true })
	defaultAuthenticationStrategyId: string;
	@ManyToOne(
		() => AuthenticationStrategy,
		authenticationStrategy => authenticationStrategy.id,
		{
			//onDelete: 'CASCADE',
			nullable: true
		}
	)
	@JoinColumn({ name: 'defaultAuthenticationStrategyId' })
	defaultAuthenticationStrategy?: AuthenticationStrategy;

	@Column('jsonb', { default: {} })
	public settings: Settings;

	@OneToMany(
		() => Asset,
		asset => asset.site,
		{
			eager: false,
			nullable: true
		}
	)
	public assets?: Asset[] | Partial<Asset>[];

	@Column({ type: 'timestamptz', default: () => 'NOW()' })
	public created: string;

	@ManyToOne(() => Product)
	@JoinColumn({ name: 'default_subscription_product_id' })
	defaultSubscriptionProduct: Product;

	@Column('text', { name: 'default_subscription_product_id', nullable: true })
	defaultSubscriptionProductId: string;

	@ManyToOne(() => Product)
	@JoinColumn({ name: 'default_item_product_id' })
	defaultItemProduct: Product;

	@Column('text', { name: 'default_item_product_id', nullable: true })
	defaultItemProductId: string;

	public toPublic(exclude?: Array<keyof PublicSite>) {
		let pub: Partial<PublicSite> = {
			id: this.id,
			remoteId: this.remoteId,
			organizationName: this.organizationName,
			slug: this.slug,
			enabled: this.enabled,
			signupMethod: this.signupMethod,
			defaultAuthenticationStrategyId: this.defaultAuthenticationStrategyId,
			settings: this.settings,
			defaultItemProduct: this.defaultItemProduct,
			defaultSubscriptionProduct: this.defaultSubscriptionProduct,
			created: this.created
		};

		if (this.assets?.length && !exclude.includes('assets')) {
			pub.assets = (this.assets as Asset[]).map(a => new Asset(a).toPublic(['site']));
		}

		if (this.authenticationStrategies?.length && !exclude.includes('authenticationStrategies')) {
			pub.authenticationStrategies = (this.authenticationStrategies as AuthenticationStrategy[]).map(a =>
				new AuthenticationStrategy(a).toPublic()
			);
		}

		if (this.defaultAuthenticationStrategy && !exclude.includes('defaultAuthenticationStrategy')) {
			pub.defaultAuthenticationStrategy = new AuthenticationStrategy(this.defaultAuthenticationStrategy).toPublic();
		}

		return pub as PublicSite;
	}
}
