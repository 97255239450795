import { Injectable, LOCALE_ID, inject } from '@angular/core';
import { catchError, combineLatest, distinctUntilChanged, map, Observable, of, skip, take, tap } from 'rxjs';
import { getLocaleFromCountry, LocaleCode } from '../models/locale';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { GlobalQuery } from '../state/global';
import { LocalStorageService } from './local-storage.service';
import { invert } from 'lodash';
import { CMS_LOCALES } from '../state/global/global.data';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { i18n, TRANSLATION_RECORDS } from '../../../i18n';
import { HttpClient } from '@angular/common/http';

export enum LocaleCountry {
	Argentina = 'argentina',
	Australia = 'australia',
	Austria = 'austria',
	Belgium = 'belgium',
	Bosnia = 'bosnia',
	Brazil = 'brazil',
	Bulgaria = 'bulgaria',
	Chile = 'chile',
	Colombia = 'colombia',
	Costa_Rica = 'costa_rica',
	Croatia = 'croatia',
	Cyprus = 'cyprus',
	China = 'china',
	Canada = 'canada',
	Czechia = 'czechia',
	Denmark = 'denmark',
	El_Salvador = 'el_salvador',
	Finland = 'finland',
	France = 'france',
	Germany = 'germany',
	Greece = 'greece',
	Guatemala = 'guatemala',
	Honduras = 'honduras',
	Hong_Kong = 'hong_kong',
	Hungary = 'hungary',
	Israel = 'israel',
	Italy = 'italy',
	Luxemburg = 'luxemburg',
	Mexico = 'mexico',
	Netherlands = 'netherlands',
	New_Zealand = 'new_zealand',
	Nicaragua = 'nicaragua',
	Norway = 'norway',
	Panama = 'panama',
	Paraguay = 'paraguay',
	Peru = 'peru',
	Poland = 'poland',
	Portugal = 'portugal',
	Romania = 'romania',
	Serbia = 'serbia',
	Slovakia = 'slovakia',
	Slovenia = 'slovenia',
	South_Africa = 'south_africa',
	Spain = 'spain',
	Sweden = 'sweden',
	Switzerland = 'switzerland',
	Thailand = 'thailand',
	UK = 'uk',
	US = 'united_states',
	Uruguay = 'uruguay'
}
const INVERTED_CMS_LOCALES_MAPPING = invert(CMS_LOCALES);
const LOCALE_CODE = 'localeCode';

@Injectable({
	providedIn: 'root'
})
export class LocalizationService {
	// Make sure to add the country code from https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2#US
	private readonly localeList: any = [
		{
			name: 'Argentina',
			enumValue: LocaleCountry.Argentina,
			code: 'AR',
			value: [{ name: 'ESP', value: LocaleCode.esAR }]
		},
		{
			name: 'Australia',
			enumValue: LocaleCountry.Australia,
			code: 'AU',
			value: [{ name: 'Eng', value: LocaleCode.enGB }]
		},
		{
			name: 'Austria',
			enumValue: LocaleCountry.Austria,
			code: 'AT',
			value: [{ name: 'DE', value: LocaleCode.deDE }]
		},
		{
			name: 'Belgium / Luxemburg',
			enumValue: LocaleCountry.Belgium,
			code: 'BE',
			value: [{ name: 'DE', value: LocaleCode.deDE }]
		},
		{
			name: 'Bosnia',
			enumValue: LocaleCountry.Bosnia,
			code: 'BA',
			value: [{ name: 'CRO', value: LocaleCode.hrHR }]
		},
		{
			name: 'Brazil',
			enumValue: LocaleCountry.Brazil,
			code: 'BR',
			value: [{ name: 'Por', value: LocaleCode.ptBR }]
		},
		{
			name: 'Bulgaria',
			enumValue: LocaleCountry.Bulgaria,
			code: 'BG',
			value: [{ name: 'BG', value: LocaleCode.bgBG }]
		},
		{
			name: 'Chile',
			enumValue: LocaleCountry.Chile,
			code: 'BG',
			value: [{ name: 'ESP', value: LocaleCode.esAR }]
		},
		{
			name: 'Colombia',
			enumValue: LocaleCountry.Colombia,
			code: 'CO',
			value: [{ name: 'ESP', value: LocaleCode.esAR }]
		},
		{
			name: 'Costa Rica',
			enumValue: LocaleCountry.Costa_Rica,
			code: 'CR',
			value: [{ name: 'ESP', value: LocaleCode.esAR }]
		},
		{
			name: 'Croatia',
			enumValue: LocaleCountry.Croatia,
			code: 'BE',
			value: [{ name: 'DE', value: LocaleCode.deDE }]
		},
		{
			name: 'Czechia',
			enumValue: LocaleCountry.Czechia,
			code: 'CZ',
			value: [{ name: 'CZ', value: LocaleCode.csCZ }]
		},
		{
			name: 'Denmark',
			enumValue: LocaleCountry.Denmark,
			code: 'DK',
			value: [{ name: 'ENG', value: LocaleCode.enUS }]
		},
		{
			name: 'El Salvador',
			enumValue: LocaleCountry.El_Salvador,
			code: 'SV',
			value: [{ name: 'ESP', value: LocaleCode.esAR }]
		},
		{
			name: 'Finland',
			enumValue: LocaleCountry.Finland,
			code: 'FI',
			value: [{ name: 'ENG', value: LocaleCode.enGB }]
		},
		{
			name: 'France',
			enumValue: LocaleCountry.France,
			code: 'FR',
			value: [{ name: 'FR', value: LocaleCode.frFR }]
		},
		{
			name: 'Germany',
			enumValue: LocaleCountry.Germany,
			code: 'DE',
			value: [{ name: 'DE', value: LocaleCode.deDE }]
		},
		{
			name: 'Greece',
			enumValue: LocaleCountry.Greece,
			code: 'GR',
			value: [{ name: 'EL', value: LocaleCode.elGR }]
		},
		{
			name: 'Guatemala',
			enumValue: LocaleCountry.Guatemala,
			code: 'GT',
			value: [{ name: 'ESP', value: LocaleCode.esAR }]
		},
		{
			name: 'Honduras',
			enumValue: LocaleCountry.Honduras,
			code: 'HN',
			value: [{ name: 'ESP', value: LocaleCode.esAR }]
		},
		{
			name: 'Hong Kong',
			enumValue: LocaleCountry.Hong_Kong,
			code: 'HK',
			value: [{ name: 'ZH', value: LocaleCode.zhHK }]
		},
		{
			name: 'Hungary',
			enumValue: LocaleCountry.Hungary,
			code: 'HU',
			value: [{ name: 'HU', value: LocaleCode.huHU }]
		},
		{
			name: 'Israel',
			enumValue: LocaleCountry.Israel,
			code: 'IL',
			value: [{ name: 'HE', value: LocaleCode.heIL }]
		},
		{
			name: 'Italy',
			enumValue: LocaleCountry.Italy,
			code: 'IT',
			value: [{ name: 'IT', value: LocaleCode.itIT }]
		},
		{
			name: 'Mexico',
			enumValue: LocaleCountry.Mexico,
			code: 'MX',
			value: [{ name: 'Esp', value: LocaleCode.esAR }]
		},
		{
			name: 'Netherlands',
			enumValue: LocaleCountry.Netherlands,
			code: 'NL',
			value: [{ name: 'NL', value: LocaleCode.nlNL }]
		},
		{
			name: 'New Zealand',
			enumValue: LocaleCountry.New_Zealand,
			code: 'NZ',
			value: [{ name: 'Eng', value: LocaleCode.enGB }]
		},
		{
			name: 'Nicaragua',
			enumValue: LocaleCountry.Nicaragua,
			code: 'NI',
			value: [{ name: 'ESP', value: LocaleCode.esAR }]
		},
		{
			name: 'Norway',
			enumValue: LocaleCountry.Norway,
			code: 'NO',
			value: [{ name: 'ENG', value: LocaleCode.enGB }]
		},
		{
			name: 'Panama',
			enumValue: LocaleCountry.Panama,
			code: 'PA',
			value: [{ name: 'ESP', value: LocaleCode.esAR }]
		},
		{
			name: 'Paraguay',
			enumValue: LocaleCountry.Paraguay,
			code: 'PY',
			value: [{ name: 'ESP', value: LocaleCode.esAR }]
		},
		{
			name: 'Peru',
			enumValue: LocaleCountry.Peru,
			code: 'PE',
			value: [{ name: 'ESP', value: LocaleCode.esAR }]
		},
		{
			name: 'Poland',
			enumValue: LocaleCountry.Poland,
			code: 'PL',
			value: [{ name: 'PL', value: LocaleCode.plPL }]
		},
		{
			name: 'Portugal',
			enumValue: LocaleCountry.Portugal,
			code: 'PT',
			value: [{ name: 'POR', value: LocaleCode.ptPT }]
		},
		{
			name: 'Romania',
			enumValue: LocaleCountry.Romania,
			code: 'RO',
			value: [{ name: 'RO', value: LocaleCode.roRO }]
		},
		{
			name: 'Serbia',
			enumValue: LocaleCountry.Serbia,
			code: 'RS',
			value: [{ name: 'SR', value: LocaleCode.srLatnRS }]
		},
		{
			name: 'Slovakia',
			enumValue: LocaleCountry.Slovakia,
			code: 'SK',
			value: [{ name: 'SK', value: LocaleCode.skSK }]
		},
		{
			name: 'Slovenia',
			enumValue: LocaleCountry.Slovenia,
			code: 'SI',
			value: [{ name: 'SK', value: LocaleCode.slSI }]
		},
		{
			name: 'South Africa',
			enumValue: LocaleCountry.South_Africa,
			code: 'ZA',
			value: [{ name: 'ENG', value: LocaleCode.enGB }]
		},
		{
			name: 'Spain',
			enumValue: LocaleCountry.Spain,
			code: 'ES',
			value: [{ name: 'Esp', value: LocaleCode.esSP }]
		},
		{
			name: 'Sweden',
			enumValue: LocaleCountry.Sweden,
			code: 'SE',
			value: [{ name: 'ENG', value: LocaleCode.enGB }]
		},
		{
			name: 'Switzerland',
			enumValue: LocaleCountry.Switzerland,
			code: 'CH',
			value: [{ name: 'DE', value: LocaleCode.deDE }]
		},
		{
			name: 'Thailand',
			enumValue: LocaleCountry.Thailand,
			code: 'TH',
			value: [{ name: 'th', value: LocaleCode.thTH }]
		},
		{
			name: 'UK',
			enumValue: LocaleCountry.UK,
			code: 'UK',
			value: [{ name: 'Eng', value: LocaleCode.enGB }]
		},
		{
			name: 'Uruguay',
			enumValue: LocaleCountry.Uruguay,
			code: 'UY',
			value: [{ name: 'ESP', value: LocaleCode.esAR }]
		}
	];

	private globalQuery = inject(GlobalQuery);
	private localStorage = inject(LocalStorageService);
	private http = inject(HttpClient);
	private localeId = inject(LOCALE_ID);
	public localeCode$ = this.localStorage.getObservable(LOCALE_CODE);
	public locales$ = this.globalQuery.authenticatedSettings$.pipe(
		map(settings =>
			settings.locales.map(locale => ({
				...locale,
				code: INVERTED_CMS_LOCALES_MAPPING[locale.code] || locale.code
			}))
		)
	);
	private defaultLocaleCode$ = this.globalQuery.authenticatedSettings$.pipe(map(settings => settings.defaultLocale));

	private setInitialLocale$ = combineLatest([
		this.locales$,
		this.localeCode$,
		this.defaultLocaleCode$,
		this.detectCountry(),
		i18n.getTranslationProviders(true)
	]).pipe(
		tap(([locales, selectedLocale, defaultLocaleCode, detectedCountryLocale, translationProviders]) => {
			const isSelectedLocaleValid = locales.some(locale => locale.code === selectedLocale);

			let defaultLocale;
			if (localStorage.getItem('localeCode')) {
				defaultLocale = { code: localStorage.getItem('localeCode') };
			} else if (detectedCountryLocale) {
				defaultLocale = { code: detectedCountryLocale };
			} else {
				defaultLocale = locales.find(locale => locale.code === defaultLocaleCode);
			}

			// defaultLocale = locales.find(locale => locale.code === defaultLocaleCode);
			const hasTranslations = !!translationProviders?.find(provider => provider.provide === TRANSLATION_RECORDS)?.useValue;

			if ((!isSelectedLocaleValid && defaultLocale) || (!hasTranslations && defaultLocale)) {
				this.setLocaleCode(defaultLocale.code);
			}
		}),
		take(1),
		takeUntilDestroyed()
	);

	private reloadAppOnLocaleChange$ = this.localeCode$.pipe(
		skip(1),
		distinctUntilChanged(),
		tap(() => window.location.reload()),
		takeUntilDestroyed()
	);

	constructor() {
		this.setInitialLocale$.subscribe();
		this.reloadAppOnLocaleChange$.subscribe();
	}

	public setLocaleCode(locale: string) {
		if (!locale) {
			locale = window.navigator.language;
		}
		if (locale?.toLocaleUpperCase()?.includes('CN')) {
			locale = LocaleCode.zhCN;
			this.localStorage.set(LOCALE_CODE, locale);
		} else {
			locale = LocaleCode.enUS;
			this.localStorage.set(LOCALE_CODE, locale);
		}
	}

	public getLocaleCode() {
		return localStorage.getItem(LOCALE_CODE);
	}

	public getDefaultDateFormat() {
		const localeCode = this.getLocaleCode();

		if (localeCode === LocaleCode.zhCN || localeCode === LocaleCode.zhHK) {
			return 'MM/dd/YY';
		}

		return 'mediumDate';
	}

	public detectCountry(): Observable<any> {
		return this.http.get('https://api.country.is/').pipe(
			map((response: { country: string; ip: string }) => {
				return getLocaleFromCountry?.[response?.country];
			}),
			catchError(() => of(window.navigator.language))
		);
	}
}

export function getPaginatorIntl() {
	const paginatorIntl = new MatPaginatorIntl();

	paginatorIntl.itemsPerPageLabel = $localize`:Paginator label@@paginatorItemsPerPageLabel:Items per page:`;
	paginatorIntl.nextPageLabel = $localize`:Paginator label@@paginatorNextPageLabel:Next page`;
	paginatorIntl.previousPageLabel = $localize`:Paginator label@@paginatorPreviousPageLabel:Previous page`;
	paginatorIntl.firstPageLabel = $localize`:Paginator label@@paginatorFirstPageLabel:First page`;
	paginatorIntl.lastPageLabel = $localize`:Paginator label@@paginatorLastPageLabel:Last page`;
	paginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
		if (length == 0 || pageSize == 0) {
			return $localize`:Paginator label@@paginatorRangeEmptyLabel:0 of ${length}`;
		}

		length = Math.max(length, 0);

		const startIndex = page * pageSize;
		// If the start index exceeds the list length, do not try and fix the end index to the end.
		const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

		return $localize`:Paginator label@@paginatorRangeLabel:${startIndex + 1}-${endIndex} of ${length}`;
	};

	return paginatorIntl;
}
